import { useLoader, useWizard } from "@yaydoo-suite/sso-components";

import useUser from "../../hooks/use-user";
import { PasswordForm } from "../../templates";

const SignupStep2 = () => {
  const { nextPage } = useWizard();
  const { updateProfile } = useUser();
  const { notifyOperationInProgress, notifyOperationCompleted } = useLoader();

  const handleSubmit = async (password: string): Promise<void> => {
    notifyOperationInProgress();
    await updateProfile({
      password,
    });
    notifyOperationCompleted();
    nextPage();
  };

  return <PasswordForm handleSubmit={handleSubmit} />;
};

export default SignupStep2;
