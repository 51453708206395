import { useWizard } from "@yaydoo-suite/sso-components";

import { PasswordForm } from "../../templates";

const RecoverPasswordStep3 = () => {
  const { nextPage } = useWizard();
  const handleSubmit = async (password: string): Promise<void> => {
    console.log(password);
    nextPage();
  };

  return <PasswordForm handleSubmit={handleSubmit} />;
};

export default RecoverPasswordStep3;
