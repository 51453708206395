import { Container, Typography } from "@material-ui/core";
import { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";

import usePage from "../hooks/use-page";
import { Page } from "../enums/page";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    marginBottom: theme.spacing(3.5),
    "& h2": {
      fontSize: "2.2rem",
      fontWeight: 600,
      marginBottom: theme.spacing(0.5),
    },
    "& p": {
      fontSize: "1.2rem",
      "@media (min-width:375px)": {
        fontSize: "1.4rem",
      },
    },
    "& button": {
      border: "none",
      fontFamily: "Poppins, sans-serif",
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: "1.2rem",
      "@media (min-width:375px)": {
        fontSize: "1.4rem",
      },
      background: "none",
      color: theme.palette.info.main,
      textDecoration: "underline",
      cursor: "pointer",
    },
  },
}));

interface IHeaderProps {
  mainText: string;
  secondaryText?: string;
  linkText?: string;
  link?: Page;
}

const SectionHeader: FC<IHeaderProps> = ({
  mainText,
  secondaryText,
  linkText,
  link,
}) => {
  const classes = useStyles();
  const { goTo } = usePage();

  return (
    <Container className={classes.container} component="section" maxWidth="sm">
      <Typography component="h2">{mainText}</Typography>
      {secondaryText && (
        <Typography component="p">
          {secondaryText} &nbsp;
          {link && <button onClick={() => goTo(link)}>{linkText}</button>}
        </Typography>
      )}
    </Container>
  );
};

export default SectionHeader;
