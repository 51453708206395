/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
import "./firebase";
import "./i18n";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { webAuth } from "@yaydoo-suite/sso-components";
import { WebAuth } from "auth0-js";

import usePage from "./hooks/use-page";
import ValidationPage from "./pages/validation-page";
import WebAuthProvider from "./contexts/web-auth";
import { ErrorPage, Login, RecoveryPassword, Signup } from "./pages";
import { Page } from "./enums/page";

function App() {
  const [_, setAuth] = useState<null | WebAuth>();
  const { getPage } = usePage();
  const whenOnLoad = useCallback(() => {
    setAuth(webAuth(window.config));
  }, []);
  const page = {
    [Page.Login]: <Login />,
    [Page.Signup]: <Signup />,
    [Page.Recovery]: <RecoveryPassword />,
  };

  window.addEventListener("load", whenOnLoad);
  useEffect(() => {
    return () => {
      window.removeEventListener("load", whenOnLoad);
    };
  }, [whenOnLoad]);

  return (
    <Router>
      <Switch>
        <Route path="/error" exact>
          <ErrorPage />
        </Route>
        <Route path="/validate">
          <WebAuthProvider>
            <ValidationPage />
          </WebAuthProvider>
        </Route>
        <Route path="/">
          <WebAuthProvider>{page[getPage()]}</WebAuthProvider>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
