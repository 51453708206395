import React, { useCallback, useContext, useMemo } from 'react';

import { WizardContext } from './../contexts/wizard.context';

export interface IUseWizard {
  updateGlobalTemplate: (name: string, template: React.ReactNode) => void;
  overrideTemplate: (name: string, template: React.ReactNode) => void;
  unsetTemplate: (name: string) => void;
  getOverrideTemplate: (name: string) => React.ReactNode;
  isActive: (index: number) => boolean;
  shouldGoToNext: () => void;
  nextPage: () => void;
  prevPage: () => void;
  goToPage: (value: number) => void;
  navigate: (route: string) => void;
  onGoToNextPage: boolean;
  canGoToNext: boolean;
  enableGoToNextPage: () => void;
  disableGoToNextPage: () => void;
  reload: () => void;
  setRoutes: (value: string[]) => void;
}

const useWizard = (): IUseWizard => {
  const context = useContext(WizardContext);

  if (context === undefined) {
    throw new Error('useWizard must be used within a WizardProvider');
  }

  const {
    activeIndex,
    canGoToNext,
    onGoToNextPage,
    routes,
    setActiveIndex,
    setCanGoToNext,
    setGoToOnNextPage,
    setRoutes,
    setTemplates,
    setTime,
    templates,
  } = context;
  const goToPage = useCallback(
    (value: number) => {
      setGoToOnNextPage(false);
      setCanGoToNext(false);
      value < routes.length && setActiveIndex(value);
    },
    [routes, setActiveIndex]
  );
  const shouldGoToNext = useCallback(() => {
    setGoToOnNextPage(true);
  }, [setGoToOnNextPage]);
  const nextPage = useCallback(() => {
    goToPage(activeIndex + 1);
  }, [activeIndex, goToPage]);
  const prevPage = useCallback(() => {
    goToPage(activeIndex < 1 ? 0 : activeIndex - 1);
  }, [activeIndex, goToPage]);
  const enableGoToNextPage = useCallback(() => {
    setCanGoToNext(true);
  }, [canGoToNext, setCanGoToNext]);
  const disableGoToNextPage = useCallback(() => {
    setCanGoToNext(false);
  }, [setCanGoToNext]);
  const isActive = useCallback((index: number) => getActiveRoute(index), [
    activeIndex,
  ]);
  const getActiveRoute = (checkRouteIndex: number) => {
    const internalRoutes = routes || {};
    return internalRoutes[activeIndex] === internalRoutes[checkRouteIndex];
  };
  const reload = () => {
    setTime(new Date().getTime());
  };
  const getBuildTemplate = (
    name: string,
    {
      template,
      type,
    }: { type: 'override' | 'global'; template: React.ReactNode }
  ) => {
    const templateToConfigure = templates[name] || {};
    templates[name] = {
      ...templateToConfigure,
      [type]: template,
    };
    return templates;
  };
  const updateGlobalTemplate = useCallback(
    (name: string, template: React.ReactNode) => {
      setTemplates(getBuildTemplate(name, { template, type: 'global' }));
    },
    [templates, setTemplates]
  );
  const overrideTemplate = useCallback(
    (name: string, template: React.ReactNode) => {
      setTemplates(getBuildTemplate(name, { template, type: 'override' }));
    },
    [templates, setTemplates]
  );
  const unsetTemplate = useCallback(
    (name: string) => {
      setTemplates({ ...templates, [name]: {} });
    },
    [templates, setTemplates]
  );
  const getOverrideTemplate = useCallback(
    (name: string) => {
      return templates[name]?.override;
    },
    [templates]
  );
  const navigate = useCallback(
    (route: string) => {
      const index = routes.findIndex((item) => item === route);
      index > -1 && setActiveIndex(index);
    },
    [routes]
  );

  return useMemo(
    () => ({
      canGoToNext,
      disableGoToNextPage,
      enableGoToNextPage,
      getOverrideTemplate,
      goToPage,
      onGoToNextPage,
      isActive,
      navigate,
      nextPage,
      overrideTemplate,
      prevPage,
      shouldGoToNext,
      reload,
      setRoutes,
      unsetTemplate,
      updateGlobalTemplate,
    }),
    [
      canGoToNext,
      disableGoToNextPage,
      enableGoToNextPage,
      getOverrideTemplate,
      goToPage,
      onGoToNextPage,
      isActive,
      navigate,
      nextPage,
      overrideTemplate,
      prevPage,
      reload,
      shouldGoToNext,
      setRoutes,
      unsetTemplate,
      updateGlobalTemplate,
    ]
  );
};

export default useWizard;
