import { FormikProps } from "formik";
import { useEffect } from "react";
import { useWizard } from "@yaydoo-suite/sso-components";

const useWizardForm = <T extends {}>(form: FormikProps<T>) => {
  const {
    disableGoToNextPage,
    enableGoToNextPage,
    onGoToNextPage,
  } = useWizard();
  useEffect(() => {
    if (!form.isValid || !form.dirty) {
      disableGoToNextPage();
      return;
    }
    enableGoToNextPage();
  }, [form.isValid, form.dirty]);
  useEffect(() => {
    onGoToNextPage === true && form.submitForm();
  }, [onGoToNextPage]);
};

export default useWizardForm;
