import React, { FC } from 'react';

import Typography, { ITypographyProps } from './typography';

export interface IHeaderTextProps extends ITypographyProps {
  component?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
}

/**
 * ## Header text
 * Based on the typography component from @material-ui/core, with some styles changes.
 * For more info, [visit the docs](https://callstack.github.io/react-native-paper/button.html).
 *
 * ```typescriptreact
 * <HeaderText>Title</HeaderText>
 * ```
 */

const HeaderText: FC<IHeaderTextProps> = ({
  children,
  component = 'h1',
  ...otherProps
}) => (
  <Typography component={component} variant={component} {...otherProps}>
    {children}
  </Typography>
);

export default HeaderText;
