import { Request } from "../request";

export class PhoneValidationService {
  private http: Request;

  constructor() {
    this.http = new Request();
  }

  public async confirm(code: string, { token }: { token: string }) {
    return this.http.post(
      "auth/confirm",
      { code },
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
  }

  public async resend(phone: string, { token }: { token: string }) {
    return this.http.post(
      "auth/resend",
      { phone },
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
  }

  public async getInformation(params: any) {
    return this.http.get("auth/:tokenId/me", params);
  }
}
