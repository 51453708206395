import React, { FC } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import CountryDialCode from '../molecules/country-dial-code';
import PhoneTextField, { IPhoneTextField } from '../molecules/phone-text-field';
import { Typography } from '../atoms';

const useStyles = makeStyles((_theme) => ({
  phoneTextFieldContanier: {
    flexGrow: 1,
    '& > div > div': {
      borderRadius: '0 4px 4px 0',
      borderLeft: 'none',
    },
    '& input': {
      height: '56px',
      padding: '0 12px',
    },
  },
  error: {
    fontSize: '1.2rem',
    margin: '.3rem 1.4rem 0 1.4rem',
  },
}));

export interface ICountryPhone extends IPhoneTextField {}

const CountryPhone: FC<ICountryPhone> = ({ helperText, ...otherProps }) => {
  const classes = useStyles();

  return (
    <>
      <Grid container wrap="nowrap">
        <Grid item>
          <CountryDialCode />
        </Grid>
        <Grid item className={classes.phoneTextFieldContanier}>
          <PhoneTextField {...otherProps} />
        </Grid>
      </Grid>
      <Typography component="p" color="error" className={classes.error}>
        {helperText}
      </Typography>
    </>
  );
};

export default CountryPhone;
