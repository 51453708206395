import React, { createContext, FC, useMemo, useState } from "react";

import { IUserProfile } from "../interfaces/user";

interface IUserContext {
  profile: Partial<IUserProfile>;
  setProfile: (value: Partial<IUserProfile>) => void;
}

const UserContext = createContext<IUserContext>({} as any);

const UserProvider: FC = ({ children }) => {
  const [profile, setProfile] = useState<Partial<IUserProfile>>({
    id: "",
    name: "",
    email: "",
    phone: "",
    password: "",
  });

  const value = useMemo<IUserContext>(
    () => ({
      profile,
      setProfile,
    }),
    [profile, setProfile]
  );
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export { UserContext };
export default UserProvider;
