import { Container } from "@material-ui/core";
import { LoaderProvider } from "@yaydoo-suite/sso-components";
import { makeStyles } from "@material-ui/core/styles";
import { useRef } from "react";
import { useTranslation } from "react-i18next";

import { Header, LoginSocial, SectionHeader } from "../organisms";
import { LogInForm } from "../templates";
import { MobileFooter } from "../molecules";
import { Page } from "../enums/page";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(3.5),
  },
  main: {
    padding: 0,
  },
}));

const LoginPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const mobileFooter = useRef(null);

  return (
    <>
      <Container className={classes.container} maxWidth="xl">
        <LoaderProvider>
          <Header />
        </LoaderProvider>
        <Container className={classes.main} component="main" maxWidth="sm">
          <SectionHeader
            mainText={t("Inicia sesión")}
            secondaryText={t("¿Aún no te registras?")}
            linkText={t("Regístrate aquí")}
            link={Page.Signup}
          />
          <LoginSocial />
          <LogInForm mobileFooter={mobileFooter} />
        </Container>
      </Container>
      <MobileFooter mobileFooter={mobileFooter} />
    </>
  );
};

export default LoginPage;
