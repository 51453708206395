import React, { FC } from 'react';
import { Box, BoxProps } from '@material-ui/core';

import WizardBody from './../atoms/wizard-body';
import WizardContent from './../atoms/wizard-content';
import WizardFooter from './../molecules/wizard-footer';
import WizardHeader from './../molecules/wizard-header';
import WizardProvider from './../contexts/wizard.context';

type IWizard<P> = FC<P> & {
  Body: typeof WizardBody;
  Content: typeof WizardContent;
  Footer: typeof WizardFooter;
  Header: typeof WizardHeader;
};

export interface WizardProps extends BoxProps {}

const Wizard: IWizard<WizardProps> = ({ children, ...validProps }) => {
  return (
    <WizardProvider>
      <Box {...validProps}>{children}</Box>
    </WizardProvider>
  );
};

Wizard.Body = WizardBody;
Wizard.Content = WizardContent;
Wizard.Footer = WizardFooter;
Wizard.Header = WizardHeader;

export default Wizard;
