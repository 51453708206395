import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  typography: {
    fontFamily: "Poppins, sans-serif",
    htmlFontSize: 10,
    button: {
      fontFamily: "Poppins, sans-serif",
    },
  },
  palette: {
    action: {
      active: "#FFF",
      disabledBackground: "#BED0DD",
      disabled: "#FFF",
    },
    info: {
      main: "#1890FF",
    },
    success: {
      main: "#52C41A",
    },
    warning: {
      main: "#FAAD14",
    },
  },
});

export default theme;
