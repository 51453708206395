import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import logo from '../assets/images/logo.png';
import logoWhite from '../assets/images/logo-white-yaydoo.png';

export interface ILogoProps {
  white?: boolean;
}

const useStyles = makeStyles((_theme) => ({
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const Logo: FC<ILogoProps> = ({ white }) => {
  const classes = useStyles();

  return (
    <div className={classes.logoContainer}>
      <img src={white ? logoWhite : logo} alt="Logo de Yaydoo" height={45} />
    </div>
  );
};

export default Logo;
