import React, { FC } from 'react';
import { Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((_theme) => ({
  avatar: {
    background: '#DDEAF3',
    '& img': {
      objectFit: 'contain',
      transform: 'scale(.8)',
    },
  },
}));

export interface ILogoAvatar {
  src: string;
  alt: string;
}

const LogoAvatar: FC<ILogoAvatar> = ({ src, alt }) => {
  const classes = useStyles();
  return <Avatar className={classes.avatar} src={src} alt={alt} />;
};

export default LogoAvatar;
