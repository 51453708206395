import { v4 } from "uuid";

class ObjectHelper {
  static refSelf: ObjectHelper;

  public getNotUndefined<T extends Record<string, unknown>>(
    values: T
  ): Partial<T> {
    const filterValues: Record<string, unknown> = {};
    Object.keys(values)
      .filter((key) => typeof values[key] !== "undefined")
      .forEach((key) => {
        filterValues[key] = values[key];
      });
    return filterValues as T;
  }

  public getUserId(): string {
    let userId = localStorage.getItem(process.env.REACT_APP_FIREBASE_USER_KEY!);
    if (!userId) {
      userId = v4();
      localStorage.setItem(process.env.REACT_APP_FIREBASE_USER_KEY!, userId);
    }
    return userId;
  }

  public removeUserId(): void {
    localStorage.removeItem(process.env.REACT_APP_FIREBASE_USER_KEY!);
  }

  public static getInstance() {
    if (!this.refSelf) {
      this.refSelf = new ObjectHelper();
    }
    return this.refSelf;
  }
}

export { ObjectHelper };
