import { IUserRepository } from "./../interfaces/user.repository";
import { User } from "./../enums/user";

const userAlreadyExist = async (
  userRepository: IUserRepository,
  email: string
) => {
  const users = await userRepository.findByEmail(email);
  return users.findIndex((user) => user.status === User.Active) !== -1;
};

export { userAlreadyExist };
