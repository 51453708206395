import React, { createContext, FC, useMemo } from 'react';
import { AuthOptions, WebAuth } from 'auth0-js';

interface IWebProviderProps {
  config: AuthOptions;
}

interface IWebAuthContext {
  webAuth: WebAuth | null;
}

export const AuthContext = createContext<IWebAuthContext>({} as any);

const AuthProvider: FC<IWebProviderProps> = ({ children, config }) => {
  const value = useMemo<IWebAuthContext>(
    () => ({
      webAuth: new WebAuth(config),
    }),
    [config]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
