import React, { FC } from 'react';
import {
  Button as ButtonMui,
  ButtonProps,
  CircularProgress,
  makeStyles,
} from '@material-ui/core';

export interface IButtonProps extends ButtonProps {
  isLoading?: boolean;
  className?: string;
  onClick?: () => Promise<void>;
}

const useStyles = makeStyles((theme) => ({
  buttonBase: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    fontSize: '1.6rem',
    fontWeight: 600,
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.success.light,
    },
  },
  loader: {
    color: theme.palette.common.white,
  },
}));

const Button: FC<IButtonProps> = ({
  children,
  isLoading = false,
  variant = 'contained',
  fullWidth = true,
  className,
  onClick,
  ...otherProps
}) => {
  const { buttonBase, loader } = useStyles();

  return (
    <ButtonMui
      variant={variant}
      fullWidth={fullWidth}
      className={className ? buttonBase.concat(' ', className) : buttonBase}
      onClick={onClick}
      {...otherProps}
    >
      {isLoading ? <CircularProgress size={30} className={loader} /> : children}
    </ButtonMui>
  );
};

export default Button;
