import { Button, useLoader } from "@yaydoo-suite/sso-components";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  button: {
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(3),
    },
  },
}));

const WizardButton = ({ context }: any) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isLoading } = useLoader();

  return (
    <Button
      type="submit"
      className={classes.button}
      disabled={!context.canGoToNext || isLoading}
      onClick={context.shouldGoToNext}
      isLoading={isLoading}
    >
      {t("Continuar")}
    </Button>
  );
};

export default WizardButton;
