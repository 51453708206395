import { useEffect, useState } from "react";

const useCounter = ({
  milliseconds,
  initialValue,
}: {
  milliseconds: number;
  initialValue: number;
}) => {
  const [counter, setCounter] = useState(initialValue);

  useEffect(() => {
    const counterTimerId = setInterval(
      () =>
        setCounter((prevCounter) => {
          prevCounter === 0 && clearInterval(counterTimerId);
          return prevCounter > 0 ? prevCounter - 1 : 0;
        }),
      milliseconds
    );

    return () => clearInterval(counterTimerId);
  }, [initialValue]);

  return {
    counter,
  };
};

export default useCounter;
