import * as Yup from "yup";
import i18n from "i18next";
import { debounce } from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  Typography,
  useLoader,
  useWizard,
  Wizard,
} from "@yaydoo-suite/sso-components";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";

import useUser from "./../../hooks/use-user";
import UserRepository from "../../repositories/user.repository";
import { Links } from "../../enums/links";
import { LoginSocial, SectionHeader } from "../../organisms";
import { Page } from "../../enums/page";
import { useWizardForm } from "../../hooks";
import { userAlreadyExist } from "../../helpers/user.helpers";

const ASYNC_VALIDATION_TIMEOUT_IN_MS = 500;

const validationFunction = async (
  value: string,
  resolve: (value: boolean) => void
) => {
  const userRepository = UserRepository.getInstance();
  return resolve(!(await userAlreadyExist(userRepository, value)));
};

const debouncedValidation = debounce(
  validationFunction,
  ASYNC_VALIDATION_TIMEOUT_IN_MS
);

const validationHelper = async (
  value: unknown,
  context: Yup.TestContext<{}>,
  schemas: ReadonlyArray<Yup.BaseSchema>
) => {
  try {
    const schemasValidations = schemas.map((schema) =>
      schema.validate(value, { context })
    );
    await Promise.all(schemasValidations);
  } catch (error) {
    if (error instanceof Yup.ValidationError) {
      return context.createError({ message: error.message });
    }
    throw error;
  }
  return true;
};

const yupTestSequence = (
  name: string,
  ...schemas: ReadonlyArray<Yup.BaseSchema>
): Yup.TestConfig => {
  return {
    name,
    test: async (value, context) => validationHelper(value, context, schemas),
  };
};

interface ISignupFormValues {
  email?: string;
}

const validationSchema = Yup.object({
  email: Yup.string().test(
    yupTestSequence(
      "email",
      Yup.string()
        .required(i18n.t("Campo requerido"))
        .email(i18n.t("El campo debe ser un correo electrónico válido")),
      Yup.string().test({
        name: "Check email",
        message: i18n.t("El correo ya fue registrado"),
        test: (value = "") =>
          new Promise((resolve) => debouncedValidation(value, resolve)),
      })
    )
  ),
});

const useStyles = makeStyles((theme) => ({
  termsAndConditions: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    lineHeight: "2.1rem",
    fontSize: "1.2rem",
    "@media (min-width:375px)": {
      fontSize: "1.4rem",
    },
    [theme.breakpoints.up("sm")]: {
      marginBottom: 0,
    },
    "& a": {
      color: theme.palette.info.main,
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  error: {
    fontSize: "1.2rem",
    lineHeight: "1.5rem",
  },
}));

const SignupStep1 = () => {
  const { nextPage } = useWizard();

  const classes = useStyles();
  const { t } = useTranslation();
  const { notifyOperationInProgress, notifyOperationCompleted } = useLoader();
  const { updateProfile } = useUser();
  const handleOnSubmit = async ({
    email = "",
  }: ISignupFormValues): Promise<void> => {
    notifyOperationInProgress();
    await updateProfile({ email });
    notifyOperationCompleted();
    nextPage();
  };
  const signupForm = useFormik<ISignupFormValues>({
    initialValues: {
      email: "",
    },
    validationSchema,
    onSubmit: handleOnSubmit,
  });
  useWizardForm<ISignupFormValues>(signupForm);

  return (
    <>
      <Wizard.Header>
        <SectionHeader
          mainText={t("Crea una cuenta nueva")}
          secondaryText={t("¿Ya tienes cuenta?")}
          linkText={t("Inicia sesión")}
          link={Page.Login}
        />
        <LoginSocial />
      </Wizard.Header>
      <form onSubmit={signupForm.handleSubmit}>
        <TextField
          type="email"
          autoFocus
          variant="filled"
          onChange={signupForm.handleChange}
          value={signupForm.values.email}
          name="email"
          fullWidth
          label={t("Correo electrónico")}
          error={!!signupForm.errors.email}
          touched={signupForm.touched.email}
          helperText={signupForm.errors.email}
          placeholder={t("p. ej. {{example}}", { example: "john@email.com" })}
          onBlur={signupForm.handleBlur}
          loading={signupForm.isValidating}
        />
        <Typography component="p" className={classes.termsAndConditions}>
          {t("Al presionar en Continuar, estas aceptando todos nuestros")}
          &nbsp;
          <a href={Links.Terms} target="_blank" rel="noopener noreferrer">
            {t("Términos y Condiciones")}
          </a>
        </Typography>
      </form>
    </>
  );
};

export default SignupStep1;
