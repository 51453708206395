import { Box, Typography } from "@material-ui/core";
import {
  GoBack,
  Logo,
  Skeleton,
  useLoader,
  useWizard,
} from "@yaydoo-suite/sso-components";
import { makeStyles } from "@material-ui/core/styles";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import AppInformation from "./app-information";
import usePage from "../hooks/use-page";
import { ClientService } from "../services/client.service";
import { OnboardingSignup } from "../molecules";
import { Page } from "../enums/page";

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    marginBottom: theme.spacing(3.5),
  },
  logoAndGoBackSection: {
    position: "relative",
    marginBottom: theme.spacing(2),
    "& div": {
      justifyContent: "center",
      [theme.breakpoints.up("md")]: {
        marginBottom: 0,
      },
    },
    "& nav": {
      position: "absolute",
      top: "calc(calc(45px / 2) - calc(24px / 2))",
      "& p": {
        display: "none",
        [theme.breakpoints.up("md")]: {
          display: "inline-block",
        },
      },
    },
  },
  helperText: {
    marginRight: theme.spacing(1.5),
    fontSize: "1.8rem",
    color: theme.palette.grey[700],
  },
  appNameLoader: {
    marginLeft: theme.spacing(1.5),
  },
}));

const Header = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { getPage } = usePage();
  const { prevPage, isActive } = useWizard();
  const page = getPage();
  const clientService = new ClientService();
  const [app, setApp] = useState<Record<string, string>>({});
  const {
    isLoading,
    notifyOperationInProgress,
    notifyOperationCompleted,
  } = useLoader();
  const initAppInformation = useCallback(async (clientId) => {
    const options = {
      pathParams: {
        id: clientId,
      },
    };
    const response = await clientService.getInformation(options);
    setApp(response.getDecodedBody());
    notifyOperationCompleted();
  }, []);
  useEffect(() => {
    notifyOperationInProgress();
  }, []);
  useEffect(() => {
    if (!window.config?.clientID) {
      return;
    }
    console.log("entro");
    initAppInformation(window.config?.clientID);
  }, [window.config?.clientID]);
  const handleGoTo = () => {
    prevPage();
  };

  return (
    <header className={classes.headerContainer}>
      <section className={classes.logoAndGoBackSection}>
        {!isActive(0) && <GoBack text={t("Regresar")} goTo={handleGoTo} />}
        <Logo />
        {page === Page.Signup && <OnboardingSignup isEmailStep={isActive(0)} />}
      </section>
      <Box
        component="section"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Typography component="span" className={classes.helperText}>
          {t("Ir a")}
        </Typography>
        {isLoading && (
          <>
            <Skeleton variant="circle" width={40} height={40} />
            <Skeleton
              width={110}
              height={27}
              className={classes.appNameLoader}
            />
          </>
        )}
        {!isLoading && app && (
          <AppInformation
            logo={{
              src: app.logo,
              alt: t("Isologo de {{app}}", { app: app.name }),
            }}
            appName={app.name}
          />
        )}
      </Box>
    </header>
  );
};

export default Header;
