import { createContext, FC, useMemo, useState } from "react";
import { WebAuth } from "auth0-js";

interface IWebAuthContext {
  webAuth: WebAuth | null;
  setWebAuth: (value: WebAuth | null) => void;
}

export const WebAuthContext = createContext<IWebAuthContext>({} as any);

const WebAuthProvider: FC = ({ children }) => {
  const [webAuth, setWebAuth] = useState<WebAuth | null>(null);
  const value = useMemo<IWebAuthContext>(
    () => ({
      webAuth,
      setWebAuth,
    }),
    [setWebAuth, webAuth]
  );
  return (
    <WebAuthContext.Provider value={value}>{children}</WebAuthContext.Provider>
  );
};

export default WebAuthProvider;
