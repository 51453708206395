import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "@material-ui/core/styles";

import "./index.css";
import App from "./App";
import PageProvider from "./contexts/page.context";
import reportWebVitals from "./reportWebVitals";
import theme from "./theme";

ReactDOM.render(
  <Suspense fallback="loading">
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <PageProvider>
          <App />
        </PageProvider>
      </ThemeProvider>
    </React.StrictMode>
  </Suspense>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
