import { useCallback, useContext, useEffect } from "react";
import { webAuth } from "@yaydoo-suite/sso-components";

import { IWebAuthSignin, IWebAuthSignup } from "../interfaces/web-auth";
import { WebAuthContext } from "../contexts/web-auth";

interface IUseWebAuth {
  signup: (input: IWebAuthSignup) => Promise<any>;
  signin: (input: IWebAuthSignin) => Promise<any>;
  signinGoggle: () => Promise<void>;
}

const useWebAuth = (): IUseWebAuth => {
  const context = useContext(WebAuthContext);
  if (!context) {
    throw new Error("useWebAuth must be used within a WebAuthProvider");
  }

  const { setWebAuth, webAuth: webAuthContext } = context;
  const dispatchOnLoad = useCallback(() => {
    setWebAuth(webAuth(window.config));
  }, []);
  useEffect(() => {
    window.addEventListener("load", dispatchOnLoad);
    return () => window.removeEventListener("load", dispatchOnLoad);
  }, []);
  const signin = async ({ email, password, connection }: IWebAuthSignin) => {
    return new Promise((resolve, reject) =>
      webAuthContext?.login(
        {
          email,
          password,
          realm: connection,
        },
        (error, result) => (error ? reject(error) : resolve(result))
      )
    );
  };
  const signup = async ({
    email,
    password,
    connection,
    userMetadata,
  }: IWebAuthSignup) => {
    return new Promise((resolve, reject) =>
      webAuthContext?.signup(
        {
          email,
          connection,
          userMetadata,
          password,
        },
        (error, result) => (error ? reject(error) : resolve(result))
      )
    );
  };
  const signinGoggle = async () => {
    webAuthContext?.authorize({
      connection: "google-oauth2",
    });
  };
  return {
    signin,
    signup,
    signinGoggle,
  };
};

export default useWebAuth;
