import * as Yup from "yup";
import i18n from "i18next";
import {
  CountryPhone,
  Typography,
  useLoader,
  useWizard,
  Wizard,
} from "@yaydoo-suite/sso-components";
import { makeStyles } from "@material-ui/core/styles";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";

import useUser from "../../hooks/use-user";
import { Links } from "../../enums/links";
import { PhoneValidationService } from "../../services/phone-validation.service";
import { SectionHeader } from "../../organisms";
import { useWizardForm } from "../../hooks";

interface ISignupFormValues {
  phone: string;
}

const validationSchema = Yup.object().shape({
  phone: Yup.string().required(i18n.t("Campo requerido")),
});

const useStyles = makeStyles((theme) => ({
  termsAndConditions: {
    marginTop: theme.spacing(3),
    fontSize: "1.2rem",
    maxWidth: "28rem",
    lineHeight: "2.1rem",
    "@media (min-width:375px)": {
      maxWidth: "90%",
      fontSize: "1.4rem",
    },
    "& a": {
      color: theme.palette.info.main,
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  error: {
    fontSize: "1.2rem",
    lineHeight: "1.5rem",
  },
}));

const SignupStep4 = () => {
  const { nextPage } = useWizard();
  const { t } = useTranslation();
  const { updateProfile } = useUser();
  const classes = useStyles();
  const phoneService = new PhoneValidationService();
  const { notifyOperationInProgress, notifyOperationCompleted } = useLoader();
  const urlSearch = new URLSearchParams(window.location.search);
  const handleOnSubmit = async ({
    phone,
  }: ISignupFormValues): Promise<void> => {
    notifyOperationInProgress();
    await updateProfile({ phone });
    await phoneService.resend(process.env.REACT_APP_DUMMY_PHONE!, {
      token: urlSearch.get("token")!,
    });
    notifyOperationCompleted();
    nextPage();
  };
  const signupForm = useFormik<ISignupFormValues>({
    initialValues: {
      phone: "",
    },
    validationSchema,
    onSubmit: handleOnSubmit,
  });
  useWizardForm<ISignupFormValues>(signupForm);

  return (
    <>
      <Wizard.Header>
        <SectionHeader
          mainText={t("Verifica tu número")}
          secondaryText={t(
            "Por razones de seguridad, YaydooSuite quiere confirmar tu identidad."
          )}
        />
      </Wizard.Header>
      <form onSubmit={signupForm.handleSubmit}>
        <CountryPhone
          name="phone"
          type="tel"
          variant="filled"
          onChange={signupForm.handleChange}
          value={signupForm.values.phone}
          onBlur={signupForm.handleBlur}
          error={!!signupForm.errors.phone}
          touched={signupForm.touched.phone}
          helperText={signupForm.errors.phone}
        />
        <Typography component="p" className={classes.termsAndConditions}>
          {t(
            "Tu información personal es privada y está protegida, para más información consulta"
          )}
          &nbsp;
          <a href={Links.Terms} target="_blank" rel="noopener noreferrer">
            {t("Términos y Condiciones")}
          </a>
        </Typography>
      </form>
    </>
  );
};

export default SignupStep4;
