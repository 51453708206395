import React, { FC } from 'react';

import Overridable from './../templates/wizard-override';
import { IOverridable } from './../interfaces/template';
import { ReactChildrenWizardType } from './../types/wizard';
import { renderChildrenWithWizard } from './../utils';

interface IWizardFooterProps extends IOverridable {
  children: ReactChildrenWizardType;
}

const WizardFooter: FC<IWizardFooterProps> = ({ children, ...validProps }) => {
  return (
    <Overridable template="footer" {...validProps}>
      {renderChildrenWithWizard(children)}
    </Overridable>
  );
};

export default WizardFooter;
