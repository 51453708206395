import React, { FC } from 'react';

import Overridable from './../templates/wizard-override';
import { IOverridable } from './../interfaces/template';

const WizardHeader: FC<IOverridable> = (props) => {
  return <Overridable template="header" {...props} />;
};

export default WizardHeader;
