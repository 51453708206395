import React, { FC } from 'react';
import { Skeleton as SkeletonMui, SkeletonProps } from '@material-ui/lab';

export interface ISkeletonProps extends SkeletonProps {}

const Skeleton: FC<ISkeletonProps> = ({ children, ...otherProps }) => {
  return <SkeletonMui {...otherProps}>{children}</SkeletonMui>;
};

export default Skeleton;
