import { Container, Grid } from "@material-ui/core";
import { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    paddingTop: theme.spacing(3.5),
  },
  formAndSocialContainers: {
    padding: 0,
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexDirection: "column-reverse",
    },
  },
}));

interface ILayoutProps {
  header: JSX.Element;
  form: JSX.Element;
  social: JSX.Element;
}

export const Layout: FC<ILayoutProps> = ({ header, form, social }) => {
  const classes = useStyles();

  return (
    <Container className={classes.mainContainer} maxWidth="lg">
      <Grid container direction="column" spacing={0}>
        {header}
        <Container
          className={classes.formAndSocialContainers}
          component="main"
          maxWidth="sm"
        >
          {form}
          {social}
        </Container>
      </Grid>
    </Container>
  );
};

export default Layout;
