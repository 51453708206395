import { makeStyles } from "@material-ui/core";
import { Typography } from "@yaydoo-suite/sso-components";
import { useTranslation } from "react-i18next";

import useCounter from "../hooks/use-counter";

const useStyles = makeStyles((theme) => ({
  supportText: {
    textAlign: "center",
    fontSize: "1.4rem",
    fontWeight: 600,
  },
  codeButton: {
    display: "block",
    margin: "0 auto",
    border: "none",
    fontSize: "1.4rem",
    fontWeight: 600,
    color: theme.palette.info.main,
    textAlign: "center",
    textDecoration: "underline",
    background: "none",
    cursor: "pointer",
  },
  codeExpiration: {
    textAlign: "center",
    fontSize: "1.4rem",
    fontWeight: 600,
    color: theme.palette.info.main,
  },
}));
const TokenInformation = ({
  token,
  resendToken,
}: {
  token: number;
  resendToken: () => void;
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { counter } = useCounter({
    milliseconds: 1000,
    initialValue: token,
  });

  return (
    <>
      {counter > 0 ? (
        <>
          <Typography component="p" className={classes.supportText}>
            {t("Podrás solicitar un código nuevo en")}
          </Typography>
          <Typography
            component="p"
            className={classes.codeExpiration}
          >{`${counter} segundos`}</Typography>
        </>
      ) : (
        <button className={classes.codeButton} onClick={resendToken}>
          {t("Reenviar código")}
        </button>
      )}
    </>
  );
};

export default TokenInformation;
