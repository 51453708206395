import * as Yup from "yup";
import get from "lodash/get";
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  Typography,
  useLoader,
  useWizard,
} from "@yaydoo-suite/sso-components";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";

import useUser from "../../hooks/use-user";
import { getErrorMessage } from "../../catalog/api-errors.catalog";
import { PhoneValidationService } from "../../services/phone-validation.service";
import { SectionHeader } from "../../organisms";
import { TokenInformation } from "../../molecules";
import { User } from "../../enums/user";
import { useWizardForm } from "../../hooks";

interface ISignupFormValues {
  code: string;
}

const validationSchema = Yup.object().shape({
  code: Yup.string().min(6).required(),
});

const useStyles = makeStyles((theme) => ({
  form: {
    "& div": {
      justifyContent: "space-between",
      "& div": {
        boxSizing: "border-box",
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "5rem",
        height: "6rem",
        fontSize: "3rem",
        fontWeight: 600,
        borderRadius: "5px",
        "&:empty": {
          backgroundColor: "#DDEAF3",
          opacity: 0.4,
        },
        '&[class*="makeStyles-boxHighlight-"]': {
          backgroundColor: "#DDEAF3",
          border: "2px solid #24478C",
          color: theme.palette.common.black,
        },
        [theme.breakpoints.up("sm")]: {
          width: "7rem",
          height: "8.2rem",
        },
      },
    },
  },
  updatePhone: {
    marginTop: -theme.spacing(4),
    marginBottom: theme.spacing(3.5),
    fontSize: "1.2rem",
    "@media (min-width:375px)": {
      fontSize: "1.4rem",
    },
    "& span": {
      marginLeft: theme.spacing(0.5),
      fontWeight: 600,
      color: theme.palette.info.main,
      cursor: "pointer",
    },
  },
  helperText: {
    fontSize: "1.6rem",
    fontWeight: 600,
    marginBottom: theme.spacing(3),
  },
  errorText: {
    fontSize: "1.2rem",
    lineHeight: "1.5rem",
    color: theme.palette.error.main,
  },
}));

const SignupStep5 = () => {
  const { t } = useTranslation();
  const { getProfile, updateProfile } = useUser();
  const classes = useStyles();
  const { notifyOperationInProgress, notifyOperationCompleted } = useLoader();
  const { navigate } = useWizard();
  const handleOnSubmit = async ({ code }: ISignupFormValues): Promise<void> => {
    const urlParams = new URLSearchParams(window.location.search);
    const state = urlParams.get("state");
    try {
      notifyOperationInProgress();
      await phoneValidationService.confirm(code, {
        token: urlSearch.get("token")!,
      });
      await updateProfile({
        status: User.Active,
        name: "",
        password: "",
        phone: "",
      });
      window.location.href = `https://login.beta.yaydoo.com/continue?state=${state}`;
    } catch (error) {
      notifyOperationCompleted();
      setApiError(getErrorMessage(get(error, "statusCode")));
    }
  };
  const signupForm = useFormik<ISignupFormValues>({
    initialValues: {
      code: "",
    },
    validationSchema,
    onSubmit: handleOnSubmit,
  });
  useWizardForm<ISignupFormValues>(signupForm);
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState<number | null>(null);
  const [apiError, setApiError] = useState<string | null>(null);
  const phoneValidationService = new PhoneValidationService();
  const onChangePhone = () => {
    navigate("/phone");
  };
  const urlSearch = new URLSearchParams(window.location.search);
  const resendToken = async () => {
    setLoading(true);
    const { phone } = getProfile();
    try {
      await phoneValidationService.resend(phone!, {
        token: urlSearch.get("token")!,
      });
      await getTokenInformation();
    } catch (error) {
      setLoading(false);
      setApiError(getErrorMessage(get(error, "statusCode")));
    }
  };
  useEffect(() => {
    getTokenInformation();
  }, []);

  const getTokenInformation = async () => {
    try {
      const options = {
        pathParams: {
          tokenId: 987654,
        },
      };
      const response = await phoneValidationService.getInformation(options);
      setToken(Number(response.getDecodedBody().validation_remaining_time));
    } catch (error) {
      setApiError(getErrorMessage(get(error, "statusCode")));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <SectionHeader
        mainText={t("Acabamos de enviarte un SMS")}
        secondaryText={t(
          "Para confirmar tu cuenta, introduce el código de seguridad que te hemos enviado al ********{{phone}}",
          { phone: "5568" }
        )}
      />
      <form className={classes.form} onSubmit={signupForm.handleSubmit}>
        <Typography component="p" className={classes.updatePhone}>
          {t("¿Este no es tu número?")}
          <Typography component="span" onClick={onChangePhone}>
            {t("Cambiar número")}
          </Typography>
        </Typography>
        <Typography component="p" className={classes.helperText}>
          {t("Introduce tu código de seguridad de 6 dígitos aquí")}
        </Typography>
        <TextField
          name="code"
          type="partial"
          autoFocus
          autoComplete="off"
          onChange={signupForm.handleChange}
          value={signupForm.values.code}
          length={6}
        />
        {apiError && (
          <Typography component="p" className={classes.errorText}>
            {apiError}
          </Typography>
        )}
      </form>
      {!loading && typeof token === "number" && (
        <TokenInformation token={token} resendToken={resendToken} />
      )}
    </>
  );
};

export default SignupStep5;
