import axios, { Method } from "axios";
import { get, isEmpty } from "lodash";

import { IBodyRequest, IOptionsRequest } from "./interfaces/request";
import { Response } from "./response";

export class Request {
  private apiUrl: string;
  private responseFactory!: () => Response;

  constructor() {
    this.apiUrl = process.env.REACT_APP_BASE_URL!;
    this.responseFactory = () => new Response();
  }

  public async get(
    endpoint: string,
    options?: IOptionsRequest
  ): Promise<Response> {
    return this.custom(endpoint, "GET", undefined, options);
  }

  public async post(
    endpoint: string,
    body: IBodyRequest,
    options?: IOptionsRequest
  ): Promise<Response> {
    return this.custom(endpoint, "POST", body, options);
  }

  public async custom(
    endpoint: string,
    method: Method,
    body?: IBodyRequest,
    options?: IOptionsRequest
  ): Promise<Response> {
    const headers = {
      "Content-Type": "application/json",
      ...get(options, "headers", {}),
    };

    const url = this.getUrl(endpoint, { isFullUrl: options?.isFullUrl });
    const response = this.responseFactory();
    let rawResponse;
    try {
      const axiosRequest = axios.create();
      const dirtyUrl = axiosRequest.getUri({
        url,
        params: options?.queryParams,
      });
      const cleanUrl = this.cleanUrl(dirtyUrl, options?.pathParams);
      console.log({
        url: cleanUrl,
        method,
        headers,
        data: body,
      });
      rawResponse = await axiosRequest.request({
        url: cleanUrl,
        method,
        headers,
        data: body,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        rawResponse = error.response;
      }
    }
    response.setBody(rawResponse?.data);
    return response;
  }

  private getUrl(endpoint?: string, options?: { isFullUrl?: boolean }): string {
    if (options?.isFullUrl === true && endpoint) {
      return endpoint;
    }
    endpoint = endpoint ? endpoint?.replace(this.apiUrl, "") : "";
    if (/^(?!\/)[\w-/]+/gi.test(endpoint) || isEmpty(endpoint)) {
      return [this.apiUrl, endpoint].join("/");
    }
    return [this.apiUrl, endpoint].join("");
  }

  private cleanUrl(
    urlDirty: string,
    pathParams?: Record<string, string>
  ): string {
    let urlWithPathParams: string = urlDirty;

    if (typeof pathParams === "object") {
      Object.keys(pathParams).forEach((key) => {
        if (urlDirty.indexOf(key) !== -1) {
          urlWithPathParams = urlDirty.replace(
            ":" + key,
            encodeURI(pathParams[key])
          );
        }
      });
    }

    const url = new URL(urlWithPathParams);
    const filters: Record<string, string> = {};
    url.searchParams.forEach((value, key) => {
      filters[key] = value;
    });
    Object.entries(filters).forEach(([key, value]) => {
      url.searchParams.set(key, value);
    });
    return url.toString();
  }
}
