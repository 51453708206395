import { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Result } from "check-password-strength";
import { Typography } from "@yaydoo-suite/sso-components";
import { useTranslation } from "react-i18next";

interface IPasswordRequirmentsProps {
  passwordResult: Result<string>;
}
const useStyles = makeStyles((theme) => ({
  passwordStrengthList: {
    padding: 0,
    marginTop: theme.spacing(2),
    fontSize: "1.4rem",
    color: theme.palette.action.disabledBackground,
    webkitColumns: 2,
    mozColumns: 2,
    columns: 2,
    listStyle: "none",
    "& li": {
      paddingLeft: theme.spacing(2),
      marginBottom: theme.spacing(1.5),
      fontSize: "1.4rem",
      position: "relative",
      transition: "color 0.5s LINEAR",
    },
    "& span": {
      position: "absolute",
      top: ".5rem",
      left: "0",
      display: "inline-block",
      width: "8px",
      height: "8px",
      marginRight: theme.spacing(1),
      borderRadius: "50%",
      backgroundColor: "#BED0DD",
    },
  },
  passwordRequirmentCheck: {
    color: "#5F708A",
    "& span": {
      backgroundColor: "#5F708A",
    },
  },
}));

const PasswordRequirments: FC<IPasswordRequirmentsProps> = ({
  passwordResult,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const passwordRequirments = [
    {
      value: "1 minúscula",
      validation: () => passwordResult.contains.includes("lowercase"),
    },
    {
      value: "1 mayúscula",
      validation: () => passwordResult.contains.includes("uppercase"),
    },
    {
      value: "1 número",
      validation: () => passwordResult.contains.includes("number"),
    },
    {
      value: "1 caracter especial (!#$^*.)",
      validation: () => passwordResult.contains.includes("symbol"),
    },
    {
      value: "Longitud mínima de 8 caracteres",
      validation: () => passwordResult.length >= 8,
    },
  ];

  return (
    <Typography component="ul" className={classes.passwordStrengthList}>
      {passwordRequirments.map((requirment) => (
        <Typography
          component="li"
          key={requirment.value}
          className={
            requirment.validation() ? classes.passwordRequirmentCheck : ""
          }
        >
          <Typography component="span"></Typography>
          {t(requirment.value)}
        </Typography>
      ))}
    </Typography>
  );
};

export default PasswordRequirments;
