import * as Yup from "yup";
import i18n from "i18next";
import { makeStyles } from "@material-ui/core/styles";
import {
  Alert,
  TextField,
  useLoader,
  useWizard,
  Wizard,
} from "@yaydoo-suite/sso-components";
import { useFormik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import useUser from "../../hooks/use-user";
import useWebAuth from "../../hooks/use-web-auth";
import { SectionHeader } from "../../organisms";
import { useWizardForm } from "../../hooks";

interface ISignupFormValues {
  name: string;
}

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .matches(
      /^(?:([A-Z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]{1,100}))$/gi,
      i18n.t("Nombre incompleto")
    )
    .required(i18n.t("Campo requerido")),
});

const useStyles = makeStyles((theme) => ({
  alert: {
    marginBottom: theme.spacing(3),
  },
}));

const SignupStep3 = () => {
  const { nextPage } = useWizard();
  const { t } = useTranslation();
  const { getProfile, updateProfile, removeProfile } = useUser();
  const { notifyOperationInProgress, notifyOperationCompleted } = useLoader();
  const { signup, signin } = useWebAuth();
  const [signupError, setSignupError] = useState<string | null>(null);
  const classes = useStyles();
  const handleOnSubmit = async ({ name }: ISignupFormValues): Promise<void> => {
    const { password, email } = getProfile();

    try {
      notifyOperationInProgress();
      await updateProfile({
        name,
      });
      await signup({
        password: password!,
        connection: "Username-Password-Authentication",
        email: email!,
        userMetadata: {
          name: name,
        },
      });
      await removeProfile();
      await signin({
        email: email!,
        password: password!,
        connection: "Username-Password-Authentication",
      });
      nextPage();
    } catch (error) {
      setSignupError(
        t(
          "Ocurrió un error inesperado. Asegúrate que el correo no haya sido registrado e intente nuevamente"
        )
      );
    } finally {
      notifyOperationCompleted();
    }
  };
  const signupForm = useFormik<ISignupFormValues>({
    initialValues: {
      name: "",
    },
    validationSchema,
    onSubmit: handleOnSubmit,
  });
  useWizardForm<ISignupFormValues>(signupForm);
  return (
    <>
      <Wizard.Header>
        <SectionHeader mainText={t("¿Cuál es tu nombre completo?")} />
      </Wizard.Header>
      <form onSubmit={signupForm.handleSubmit}>
        {signupError && (
          <Alert
            severity="error"
            error={signupError}
            className={classes.alert}
          />
        )}
        <TextField
          name="name"
          autoFocus
          type="text"
          onChange={signupForm.handleChange}
          value={signupForm.values.name}
          fullWidth
          variant="filled"
          label={t("Nombre completo")}
          placeholder={t("p. ej. {{example}}", { example: "John Doe" })}
          onBlur={signupForm.handleBlur}
          error={!!signupForm.errors.name}
          touched={signupForm.touched.name}
          helperText={signupForm.errors.name}
        />
      </form>
    </>
  );
};

export default SignupStep3;
