import React, { createContext, FC, useMemo, useState } from 'react';

export interface ILoaderContext {
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
}

const LoaderContext = createContext<ILoaderContext>({} as any);

export const LoaderProvider: FC = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const value = useMemo(() => {
    return {
      isLoading,
      setIsLoading,
    };
  }, [isLoading]);

  return (
    <LoaderContext.Provider value={value}>{children}</LoaderContext.Provider>
  );
};

export default LoaderContext;
