/* eslint-disable @typescript-eslint/no-empty-function */
interface ISDKExceptionProperties {
  code: number;
  type: string;
  subcode?: number;
  params?: any;
}

export class SDKException extends Error {
  public statusCode: number;
  public statusSubCode?: number;
  public type: string;
  public params?: any;

  public constructor(message: string, properties: ISDKExceptionProperties) {
    super(message);

    this.statusCode = properties.code;
    this.statusSubCode = properties.subcode;
    this.params = properties.params;
    this.type = properties.type;
  }
}
