import { createPortal } from "react-dom";
import { FC } from "react";

interface IPortalProps {
  showPortal: boolean;
  elementId: string;
}

const Portal: FC<IPortalProps> = ({ showPortal, elementId, children }) => {
  if (showPortal) {
    return createPortal(children, document.getElementById(elementId)!);
  }
  return <>{children}</>;
};

export default Portal;
