import * as Yup from "yup";
import i18n from "i18next";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import { FC, useState } from "react";
import { IconButton } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Options, passwordStrength } from "check-password-strength";
import { Stepper, TextField, Wizard } from "@yaydoo-suite/sso-components";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";

import { PasswordRequirments, PasswordStrengthText } from "../molecules";
import { SectionHeader } from "../organisms";
import { useWizardForm } from "../hooks";

interface IPasswordFormProps {
  handleSubmit: (password: string) => Promise<void>;
}

interface ISignupFormValues {
  password: string;
}

const passwordSettings = [
  {
    id: 0,
    value: "Débil, demasiada corta",
    minDiversity: 0,
    minLength: 0,
  },
  {
    id: 1,
    value: "Débil, demasiada corta",
    minDiversity: 1,
    minLength: 1,
  },
  {
    id: 2,
    value: "Débil",
    minDiversity: 2,
    minLength: 2,
  },
  {
    id: 3,
    value: "Medio, aún puede mejorar",
    minDiversity: 3,
    minLength: 4,
  },
  {
    id: 4,
    value: "¡Fuerte!",
    minDiversity: 4,
    minLength: 6,
  },
  {
    id: 5,
    value: "¡Muy fuerte!",
    minDiversity: 4,
    minLength: 8,
  },
] as Options<string>;

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .test(
      "passwordCheck",
      (value) => passwordStrength(value || "", passwordSettings).id === 5
    )
    .required(i18n.t("Campo requerido")),
});

const useStyles = makeStyles((theme) => ({
  error: {
    fontSize: "1.2rem",
    lineHeight: "1.5rem",
  },
  textField: {
    marginBottom: theme.spacing(1.5),
  },
}));

const PasswordForm: FC<IPasswordFormProps> = ({ handleSubmit }) => {
  const { t } = useTranslation();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const theme = useTheme();
  const classes = useStyles();
  const handleOnSubmit = async ({
    password,
  }: ISignupFormValues): Promise<void> => {
    await handleSubmit(password);
  };
  const signupForm = useFormik<ISignupFormValues>({
    initialValues: {
      password: "",
    },
    validationSchema,
    onSubmit: handleOnSubmit,
  });
  useWizardForm<ISignupFormValues>(signupForm);
  const passwordResult = passwordStrength(
    signupForm.values.password,
    passwordSettings
  );
  const stepperColors = [
    theme.palette.error.main,
    theme.palette.warning.main,
    theme.palette.warning.main,
    theme.palette.success.main,
    theme.palette.success.main,
  ];

  return (
    <>
      <Wizard.Header>
        <SectionHeader
          mainText={t("Crea una contraseña")}
          secondaryText={t("Ingresa y confirma tu nueva contraseña")}
        />
      </Wizard.Header>
      <form onSubmit={signupForm.handleSubmit}>
        <TextField
          name="password"
          variant="filled"
          autoFocus
          type={passwordVisible ? "text" : "password"}
          onChange={signupForm.handleChange}
          value={signupForm.values.password}
          fullWidth
          label={t("Contraseña")}
          placeholder={t("Escribe tu contraseña")}
          className={classes.textField}
          onBlur={signupForm.handleBlur}
          error={!!signupForm.errors.password}
          touched={signupForm.touched.password}
          defaultIcon={
            <IconButton
              color="inherit"
              onClick={() => setPasswordVisible(!passwordVisible)}
            >
              {passwordVisible ? (
                <VisibilityOffOutlinedIcon />
              ) : (
                <VisibilityOutlinedIcon />
              )}
            </IconButton>
          }
        />
        <Stepper
          steps={5}
          activeStep={passwordResult.id}
          stepperColors={stepperColors}
        />
        <PasswordStrengthText
          activeStep={passwordResult.id}
          stepperColors={stepperColors}
          text={passwordResult.value}
        />
        <PasswordRequirments passwordResult={passwordResult} />
      </form>
    </>
  );
};

export default PasswordForm;
