import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import { TextField } from "@barrerajulio/codebit.ui.text-field";
import { Typography, useWizard } from "@yaydoo-suite/sso-components";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";

import { SectionHeader } from "../../organisms";
import { useWizardForm } from "../../hooks";

interface IRecoverPasswordFormValues {
  code: string;
}

const validationSchema = Yup.object().shape({
  code: Yup.string().min(6).required(),
});

const useStyles = makeStyles((theme) => ({
  formSecurityCode: {
    "& div": {
      justifyContent: "space-between",
      "& div": {
        boxSizing: "border-box",
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "5rem",
        height: "6rem",
        fontSize: "3rem",
        fontWeight: 600,
        borderRadius: "5px",
        "&:empty": {
          backgroundColor: "#DDEAF3",
          opacity: 0.4,
        },
        '&[class*="makeStyles-boxHighlight-"]': {
          backgroundColor: "#DDEAF3",
          border: "2px solid #24478C",
          color: theme.palette.common.black,
        },
        [theme.breakpoints.up("sm")]: {
          width: "7rem",
          height: "8.2rem",
        },
      },
    },
  },
  helperText: {
    width: "max-content",
    maxWidth: "fit-content",
    fontSize: "1.4rem",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: theme.spacing(3),
  },
  link: {
    fontSize: "1.4rem",
    fontWeight: 600,
    color: "#1890FF",
    textAlign: "center",
    textDecoration: "underline",
  },
}));

const RecoverPasswordStep2 = () => {
  const { nextPage } = useWizard();
  const classes = useStyles();
  const { t } = useTranslation();
  const handleOnSubmit = (values: IRecoverPasswordFormValues): void => {
    console.log(values);
    nextPage();
  };
  const signupForm = useFormik<IRecoverPasswordFormValues>({
    initialValues: {
      code: "",
    },
    validationSchema,
    onSubmit: handleOnSubmit,
  });
  useWizardForm<IRecoverPasswordFormValues>(signupForm);

  return (
    <>
      <SectionHeader
        mainText={t("Ingresa el código de verificación")}
        secondaryText={t(
          "Enviamos un código de 6 dígitos a antonio@yaydoo.com. El código vence en 25 segundos"
        )}
      />
      <form
        className={classes.formSecurityCode}
        onSubmit={signupForm.handleSubmit}
      >
        <TextField
          name="code"
          type="partial"
          onChange={signupForm.handleChange}
          value={signupForm.values.code}
          length={6}
        />
        <Typography component="p" className={classes.helperText}>
          {t("¿No encuentras tu código? Revisa tu carpeta de spam")}
        </Typography>
        <Typography component="p" className={classes.link}>
          {t("Reenviar código")}
        </Typography>
      </form>
    </>
  );
};

export default RecoverPasswordStep2;
