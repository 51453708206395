import { FC, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import useUser from "../hooks/use-user";

const useStyles = makeStyles((theme) => ({
  emailText: {
    position: "absolute",
    top: 0,
    right: 0,
    display: "none",
    fontWeight: 600,
    color: "#919EB1",
    textAlign: "right",
    [theme.breakpoints.up("md")]: {
      display: "inline-block",
    },
    "& span": {
      color: theme.palette.info.main,
    },
  },
}));

interface IOnboardingSignupProps {
  isEmailStep: boolean;
}
const OnboardingSignup: FC<IOnboardingSignupProps> = ({ isEmailStep }) => {
  const { getProfile } = useUser();
  const classes = useStyles();
  const { t } = useTranslation();
  const email = useMemo(() => {
    return getProfile().email;
  }, [getProfile().email]);

  if (isEmailStep) {
    return null;
  }

  return (
    <Typography component="span" className={classes.emailText}>
      {t("Registrándote con")} <br />
      <Typography component="span">{email}</Typography>
    </Typography>
  );
};

export default OnboardingSignup;
