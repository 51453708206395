import { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@yaydoo-suite/sso-components";
import { useTranslation } from "react-i18next";

interface IPasswordStrengthTextProps {
  activeStep: number;
  stepperColors: string[];
  text: string;
}

const useStyles = makeStyles((theme) => ({
  passwordStrengthText: {
    marginTop: theme.spacing(1.5),
    fontSize: "1.4rem",
    textAlign: "right",
    color: ({
      stepperColors,
      activeStep,
    }: {
      stepperColors: string[];
      activeStep: number;
    }) => (activeStep === 0 ? "#5F708A" : `${stepperColors[activeStep - 1]}`),
  },
}));

const PasswordStrengthText: FC<IPasswordStrengthTextProps> = ({
  activeStep,
  stepperColors,
  text,
}) => {
  const classes = useStyles({ activeStep, stepperColors });
  const { t } = useTranslation();

  return (
    <Typography component="p" className={classes.passwordStrengthText}>
      {t(text)}
    </Typography>
  );
};

export default PasswordStrengthText;
