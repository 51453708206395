import React, { FC } from "react";

import RecoverPasswordStep1 from "./recovery-password-step-1";
import RecoverPasswordStep2 from "./recovery-password-step-2";
import RecoverPasswordStep3 from "./recovery-password-step-3";
import { RecoverPasswordType } from "./types";

interface IRecoverPasswordProps {
  type: RecoverPasswordType;
}

const RecoverPassword: FC<IRecoverPasswordProps> = ({ type }) => {
  const recoverPasswordTypes: { [key: string]: FC } = {
    email: RecoverPasswordStep1,
    validation: RecoverPasswordStep2,
    password: RecoverPasswordStep3,
  };
  if (!recoverPasswordTypes.hasOwnProperty(type)) {
    throw new Error("The component for recover password does not exist");
  }
  return React.createElement(recoverPasswordTypes[type]);
};

export default RecoverPassword;
