import CheckIcon from '@material-ui/icons/Check';
import NumberFormat from 'react-number-format';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import React, { FC } from 'react';
import {
  CircularProgress,
  InputAdornment,
  TextField as TextFieldMui,
  TextFieldProps as TextFieldPropsMui,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import TextFieldPartial, { TextFieldPartialProps } from './text-field-partial';
import { BaseTextFieldProps } from './../types/text-field';

interface TextFieldMuiProps
  extends Omit<TextFieldPropsMui, 'type'>,
    BaseTextFieldProps {
  type:
    | 'color'
    | 'date'
    | 'datetime'
    | 'email'
    | 'file'
    | 'hidden'
    | 'image'
    | 'month'
    | 'number'
    | 'password'
    | 'radio'
    | 'range'
    | 'reset'
    | 'search'
    | 'submit'
    | 'tel'
    | 'text'
    | 'time'
    | 'url'
    | 'week';
}

interface IAdornmentProps {
  touched: boolean;
  error: boolean;
  iconStyles: string;
  defaultIcon?: React.ReactNode;
  loading?: boolean;
}

export type TextFieldProps = (TextFieldPartialProps | TextFieldMuiProps) & {
  touched?: boolean;
  defaultIcon?: React.ReactNode;
  loading?: boolean;
};

const useStylesAdornment = makeStyles((theme) => ({
  error: {
    color: theme.palette.error.main,
  },
  success: {
    color: theme.palette.success.main,
  },
}));

const Adornment: FC<IAdornmentProps> = ({
  defaultIcon,
  touched,
  error,
  iconStyles,
  loading,
}) => {
  const classes = useStylesAdornment();
  let icon: React.ReactNode = null;
  if (touched && error) {
    icon = defaultIcon ? (
      <span className={classes.error}>{defaultIcon}</span>
    ) : (
      <PriorityHighIcon color="error" />
    );
  } else if (touched && !error) {
    icon = defaultIcon ? (
      <span className={classes.success}>{defaultIcon}</span>
    ) : (
      <CheckIcon className={iconStyles} />
    );
  }

  if (loading) {
    icon = <CircularProgress size={20} />;
  }

  if (icon !== null) {
    return <InputAdornment position="end">{icon}</InputAdornment>;
  }
  return <>{icon}</>;
};

const NumberFormatCustom = ({ format, ...validProps }: { format: string }) => {
  return <NumberFormat format={format} {...validProps} />;
};

const useStyles = makeStyles((theme) => ({
  root: {
    border: `1px solid ${theme.palette.grey[500]}`,
    overflow: 'hidden',
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:hover': {
      backgroundColor: theme.palette.common.white,
    },
    '&$focused': {
      backgroundColor: theme.palette.common.white,
      borderColor: theme.palette.primary.main,
    },
  },
  focused: {},
  errorHelperText: {
    fontSize: '1.2rem',
    lineHeight: '1.5rem',
  },
  error: {
    border: `1px solid ${theme.palette.error.light} !important`,
  },
  input: {
    '-webkit-box-shadow': `0 0 0 100px ${theme.palette.common.white} inset`,
  },
  icon: {
    color: theme.palette.success.main,
  },
}));

const DefaultTextField: FC<TextFieldProps> = ({
  defaultIcon,
  error,
  InputProps,
  mask: format,
  touched = false,
  loading = false,
  ...validProps
}) => {
  const { errorHelperText, icon, ...classes } = useStyles();
  if (format) {
    return (
      <TextFieldMui
        InputProps={{
          classes,
          disableUnderline: true,
          endAdornment: (
            <Adornment
              error={!!error}
              touched={touched}
              iconStyles={icon}
              defaultIcon={defaultIcon}
              loading={loading}
            />
          ),
          inputComponent: NumberFormatCustom as any,
          ...InputProps,
          ...(validProps as any),
          inputProps: { format },
        }}
        {...(validProps as TextFieldPropsMui)}
        error={error}
        FormHelperTextProps={{ error: !!error, className: errorHelperText }}
      />
    );
  }
  return (
    <TextFieldMui
      InputProps={{
        classes,
        disableUnderline: true,
        endAdornment: (
          <Adornment
            error={!!error}
            touched={touched}
            iconStyles={icon}
            defaultIcon={defaultIcon}
            loading={loading}
          />
        ),
        ...InputProps,
      }}
      {...(validProps as TextFieldPropsMui)}
      FormHelperTextProps={{ error: !!error, className: errorHelperText }}
      error={error}
    />
  );
};

const TextField: FC<TextFieldProps> = ({ type, ...validProps }) => {
  if (type === 'partial') {
    return <TextFieldPartial {...(validProps as TextFieldPartialProps)} />;
  }
  return <DefaultTextField type={type} {...validProps} />;
};

export default TextField;
