import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  border: {
    borderBottom: `1px solid ${theme.palette.text.secondary}`,
    flexGrow: 1,
  },
  content: {
    paddingRight: (props: { customSpace?: number }) =>
      theme.spacing(props.customSpace || 2),
    paddingLeft: (props: { customSpace?: number }) =>
      theme.spacing(props.customSpace || 2),
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.text.disabled,
  },
}));

export interface IDividerProps {
  customSpace?: number;
}

const Divider: FC<IDividerProps> = ({ children, customSpace }) => {
  const classes = useStyles({ customSpace });

  return (
    <div className={classes.container}>
      <hr className={classes.border} />
      <span className={classes.content}>{children}</span>
      <hr className={classes.border} />
    </div>
  );
};

export default Divider;
