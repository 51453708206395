import React, { FC } from 'react';
import { Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: theme.breakpoints.values.sm,
  },
  step: {
    width: '50px',
    height: '7px',
    borderRadius: '3px',
    backgroundColor: theme.palette.action.disabledBackground,
    transform: 'skew(140deg)',
    transition: 'background-color 0.6s linear',
    [theme.breakpoints.up('md')]: {
      width: '70px',
    },
  },
  active: {
    backgroundColor: (props: IStepper) =>
      props.stepperColors
        ? `${props.stepperColors[props.activeStep - 1]}`
        : '#5F708A',
  },
}));

interface IStepper {
  steps: number;
  activeStep: number;
  stepperColors?: string[];
}

const Stepper: FC<IStepper> = ({
  steps = 5,
  activeStep = 0,
  stepperColors,
}) => {
  const classes = useStyles({ activeStep, stepperColors, steps });

  return (
    <Box className={classes.container}>
      {Array.from({ length: steps }, (_v, i) => i).map((_, index) => {
        const styles =
          index + 1 <= activeStep
            ? `${classes.step} ${classes.active}`
            : classes.step;
        return <Box key={index} className={styles}></Box>;
      })}
    </Box>
  );
};

export default Stepper;
