import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import translationEn from "./locales/en/translation.json";

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: translationEn,
      },
    },
    fallbackLng: "es",
    // saveMissing: true, // send not translated keys to endpoint
  });

export default i18n;
