import { FC } from "react";
import { LogoAvatar } from "@yaydoo-suite/sso-components";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  appNameText: {
    marginLeft: theme.spacing(1.5),
    fontSize: "1.8rem",
    color: theme.palette.grey[700],
  },
}));

interface IAppInformation {
  logo: { src: string; alt: string };
  appName: string;
}

const AppInformation: FC<IAppInformation> = ({ logo, appName }) => {
  const classes = useStyles();

  return (
    <>
      <LogoAvatar src={logo.src} alt={logo.alt} />
      <Typography component="span" className={classes.appNameText}>
        {appName}
      </Typography>
    </>
  );
};

export default AppInformation;
