import i18n from "i18next";

interface IMessageI18n {
  [key: number]: string;
}

const getErrorMessage = (code: number) => {
  const defaultMessage = "Error inseperado";

  const messageI18n: IMessageI18n = {
    5000: "El código es inválido, introdúcelo de nuevo",
    5002: "El teléfono proporcionado es incorrecto, actualizalo para poder enviarte el código de seguridad",
  };

  return i18n.t(messageI18n[code] || defaultMessage);
};

export { getErrorMessage };
