import { useContext } from 'react';

import LoaderContext from '../contexts/loader.context';

export interface IUseLoader {
  notifyOperationInProgress: () => void;
  notifyOperationCompleted: () => void;
  isLoading: boolean;
}

const useLoader = (): IUseLoader => {
  const { isLoading, setIsLoading } = useContext(LoaderContext);
  const notifyOperationInProgress = (): void => setIsLoading(true);
  const notifyOperationCompleted = (): void => setIsLoading(false);

  return {
    notifyOperationCompleted,
    notifyOperationInProgress,
    isLoading,
  };
};

export default useLoader;
