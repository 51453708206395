import { FC, MutableRefObject } from "react";
import { makeStyles } from "@material-ui/core/styles";

interface IMobileFooterProps {
  mobileFooter: MutableRefObject<null>;
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  footer: {
    position: "fixed",
    width: "calc(100% - 48px)",
    bottom: "0",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(3),
    backgroundColor: theme.palette.common.white,
    boxShadow: "0px 4px 24px 8px rgba(1, 27, 52, 0.08)",
    zIndex: 1,
    [theme.breakpoints.up("sm")]: {
      padding: 0,
      boxShadow: "none",
      zIndex: 0,
    },
  },
}));

const MobileFooter: FC<IMobileFooterProps> = ({ mobileFooter, className }) => {
  const classes = useStyles();

  return (
    <footer
      ref={mobileFooter}
      className={
        className ? classes.footer.concat(" ", className) : classes.footer
      }
      id="footer-mobile"
    />
  );
};

export default MobileFooter;
