import React, { createContext, FC, useMemo, useState } from 'react';

import { TemplateType } from './../types/wizard';

export interface IWizardContext {
  activeIndex: number;
  setActiveIndex: (value: number) => void;
  setCanGoToNext: (value: boolean) => void;
  onGoToNextPage: boolean;
  setGoToOnNextPage: (value: boolean) => void;
  setTemplates: (value: TemplateType) => void;
  canGoToNext: boolean;
  routes: string[];
  setRoutes: (value: string[]) => void;
  templates: TemplateType;
  setTime: (value: number) => void;
}

const WizardContext = createContext<IWizardContext>({} as any);

const WizardProvider: FC = ({ children }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [onGoToNextPage, setGoToOnNextPage] = useState(false);
  const [canGoToNext, setCanGoToNext] = useState(false);
  const [routes, setRoutes] = useState<string[]>([]);
  const [templates, setTemplates] = useState({});
  const [, setTime] = useState(0);
  const value = useMemo(
    () => ({
      activeIndex,
      canGoToNext,
      onGoToNextPage,
      routes,
      setActiveIndex,
      setCanGoToNext,
      setGoToOnNextPage,
      setRoutes,
      setTemplates,
      templates,
      setTime,
    }),
    [
      activeIndex,
      canGoToNext,
      onGoToNextPage,
      routes,
      setActiveIndex,
      setCanGoToNext,
      setGoToOnNextPage,
      setRoutes,
      setTemplates,
      templates,
      setTime,
    ]
  );

  return (
    <WizardContext.Provider value={value}>{children}</WizardContext.Provider>
  );
};

export default WizardProvider;

export { WizardContext };
