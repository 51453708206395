import React, { FC, useCallback, useEffect } from 'react';

import useWizard from './../hooks/use-wizard';
import { IOverridable } from './../interfaces/template';

const WizardOverride: FC<IOverridable & { template: string }> = ({
  template,
  override,
  children,
}) => {
  const {
    getOverrideTemplate,
    overrideTemplate,
    unsetTemplate,
    updateGlobalTemplate,
  } = useWizard();
  const overrideView = getOverrideTemplate(template);
  const overrideIfRequired = useCallback(
    (internalOverride, templateName: string) =>
      internalOverride === true
        ? overrideTemplate(templateName, children)
        : updateGlobalTemplate(templateName, children),
    [overrideTemplate, updateGlobalTemplate]
  );
  useEffect(() => {
    overrideIfRequired(override, template);
    return () => {
      unsetTemplate(template);
    };
  }, [overrideIfRequired, unsetTemplate]);
  if (overrideView && !override) {
    return null;
  }
  return <>{overrideView || children}</>;
};

export default WizardOverride;
