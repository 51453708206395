import React, { FC } from 'react';

import { ReactChildrenWizardType } from './../types/wizard';
import { renderChildrenWithWizard } from './../utils';

export interface IWizardContentProps {
  children: ReactChildrenWizardType;
  path: string;
}

const WizardContent: FC<IWizardContentProps> = ({ children }) => {
  return <>{renderChildrenWithWizard(children)}</>;
};

export default WizardContent;
