import { Container } from "@material-ui/core";
import { FC, useRef } from "react";
import { LoaderProvider, Wizard } from "@yaydoo-suite/sso-components";
import { makeStyles } from "@material-ui/core/styles";

import RecoverPassword from "../templates/recovery-password";
import useMobileFooter from "../hooks/use-mobile-footer";
import { Header } from "../organisms";
import { MobileFooter, Portal, WizardButton } from "../molecules";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(3.5),
    height: "calc(100vh - 92px)",
    [theme.breakpoints.up("sm")]: {
      height: "auto",
    },
  },
  main: {
    padding: 0,
  },
  footer: {
    padding: 0,
  },
}));

export const RecoveryPasswordPage: FC = () => {
  const classes = useStyles();
  const mobileFooter = useRef(null);
  const { showFooter } = useMobileFooter(mobileFooter);

  return (
    <LoaderProvider>
      <Container className={classes.container} maxWidth="xl">
        <Wizard>
          <Wizard.Header>
            <Header />
          </Wizard.Header>
          <Container className={classes.main} component="main" maxWidth="sm">
            <Wizard.Body>
              <Wizard.Content path="/email">
                <RecoverPassword type="email" />
              </Wizard.Content>
              <Wizard.Content path="/validation">
                <RecoverPassword type="validation" />
              </Wizard.Content>
              <Wizard.Content path="/password">
                <RecoverPassword type="password" />
              </Wizard.Content>
            </Wizard.Body>
          </Container>
          <Container
            className={classes.footer}
            component="footer"
            maxWidth="sm"
          >
            <Wizard.Footer>
              {(context) => (
                <Portal showPortal={showFooter} elementId="footer-mobile">
                  <WizardButton context={context} />
                </Portal>
              )}
            </Wizard.Footer>
          </Container>
        </Wizard>
      </Container>
      <MobileFooter mobileFooter={mobileFooter} />
    </LoaderProvider>
  );
};

export default RecoveryPasswordPage;
