import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import arrowLeft from '../assets/icons/arrowLeft.svg';

const useStyles = makeStyles((theme) => ({
  goBackContainer: {
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  goBackText: {
    marginLeft: theme.spacing(2),
  },
}));

export interface IGoBackProps {
  text?: string;
  goTo: () => void;
}

const GoBack: FC<IGoBackProps> = ({ text, goTo }) => {
  const classes = useStyles();

  return (
    <nav className={classes.goBackContainer} onClick={goTo}>
      <img src={arrowLeft} alt="Icono para regresar" />
      {text && (
        <Typography
          variant="subtitle2"
          component="p"
          className={classes.goBackText}
        >
          {text}
        </Typography>
      )}
    </nav>
  );
};

export default GoBack;
