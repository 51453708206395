import { MutableRefObject, useEffect, useState } from "react";
import { useMediaQuery, useTheme } from "@material-ui/core";

const useMobileFooter = (mobileFooter: MutableRefObject<null> | null) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));
  const [showFooter, setShowFooter] = useState(false);

  useEffect(() => {
    setShowFooter(!showFooter && !!mobileFooter?.current && matches);
  }, [mobileFooter?.current, matches]);

  return {
    showFooter,
  };
};

export default useMobileFooter;
