import NumberFormat from 'react-number-format';
import React, { FC } from 'react';
import {
  Typography as TypographyMui,
  TypographyProps,
} from '@material-ui/core';

import { IMaskableProps } from './../interfaces';

export interface ITypographyProps
  extends TypographyProps,
    Partial<IMaskableProps> {
  component?: React.ElementType;
  prefix?: string;
  decimalScale?: number;
  thousandSeparator?: boolean;
}

/**
 * ## Typography
 * A wrapper for the text component with the most common variations of color and font family.
 *
 * ```typescriptreact
 * <Typography variant="info">Hello World!</Typography>
 * ```
 */
const Typography: FC<ITypographyProps> = ({
  children,
  component = 'span',
  mask,
  variant = 'inherit',
  decimalScale,
  prefix,
  format,
  thousandSeparator,
  ...otherProps
}) => {
  if (mask === true && typeof children === 'string') {
    children = (
      <NumberFormat
        format={format}
        value={children}
        isNumericString
        decimalScale={decimalScale}
        displayType="text"
        prefix={prefix}
        thousandSeparator={thousandSeparator}
      />
    );
  }
  return (
    <TypographyMui variant={variant} component={component} {...otherProps}>
      {children}
    </TypographyMui>
  );
};

export default Typography;
