import { ResponseExceptionFactory } from "./response-exception.factory";

export interface IBodyResponse {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  [x: string]: any;
}
export class Response {
  private _body!: IBodyResponse;
  private _decodedBody!: IBodyResponse;
  private responseExceptionFactory!: ResponseExceptionFactory;

  constructor() {
    this.responseExceptionFactory = new ResponseExceptionFactory();
  }

  public setBody(body: IBodyResponse): void {
    this._body = body;
    this.decodeBody();
  }

  public getDecodedBody(): IBodyResponse {
    return this._decodedBody;
  }

  private decodeBody(): void {
    this._decodedBody = this._body || {};
    if (this.isError() === true) {
      this.makeException();
    }
  }

  private isError(): boolean {
    return Object.keys(this._decodedBody).includes("error");
  }

  private makeException(): void {
    throw this.responseExceptionFactory.create(this);
  }
}
