import {
  equalTo,
  FirebaseDatabase,
  getDatabase,
  onValue,
  orderByChild,
  query,
  ref,
  update,
} from "firebase/database";

import {
  IUserCriteria,
  IUserInputCreate,
  IUserModel,
  IUserRepository,
} from "../../interfaces/user.repository";
import { ObjectHelper } from "../../helpers/object.helper";

class UserFirebaseRepository implements IUserRepository {
  private static ref: UserFirebaseRepository;
  private database: FirebaseDatabase;
  private objectHelper!: ObjectHelper;

  public constructor() {
    this.database = getDatabase();
    this.objectHelper = ObjectHelper.getInstance();
  }

  public async findOne(criteria: IUserCriteria): Promise<IUserModel> {
    const row: Record<string, string> = await new Promise((resolve, reject) =>
      onValue(
        ref(this.database, `users/${criteria.id}`),
        (snapshot) => resolve(snapshot.val()),
        reject
      )
    );

    return {
      id: row.id,
      name: row.name,
      email: row.email,
      password: row.password,
      phone: row.phone,
      status: row.status,
    };
  }

  public async findByEmail(email: string): Promise<IUserModel[]> {
    const row: any = await new Promise((resolve, reject) => {
      onValue(
        query(
          ref(this.database, "users"),
          orderByChild("email"),
          equalTo(email)
        ),
        (snapshot) => {
          const coincidences = snapshot.val();
          let users = [];
          for (const key in snapshot.val()) {
            const user = {
              id: key,
              name: coincidences[key].name,
              email: coincidences[key].email,
              password: coincidences[key].password,
              phone: coincidences[key].phone,
              status: coincidences[key].status,
            };
            users.push(user);
          }
          resolve(users);
        },
        reject
      );
    });
    return row;
  }

  public async update(
    id: string,
    { name, phone, email, password, status }: Partial<IUserInputCreate>
  ): Promise<void> {
    return update(
      ref(this.database, `users/${id}`),
      this.objectHelper.getNotUndefined({
        name,
        phone,
        email,
        password,
        status,
      })
    );
  }

  public static create() {
    if (!this.ref) {
      this.ref = new UserFirebaseRepository();
    }
    return this.ref;
  }
}

export { UserFirebaseRepository };
