import React, { FC } from "react";

import SignupStep1 from "./signup-step-1";
import SignupStep2 from "./signup-step-2";
import SignupStep3 from "./signup-step-3";
import SignupStep4 from "./signup-step-4";
import SignupStep5 from "./signup-step-5";
import { SignupType } from "./types";

interface ISignupProps {
  type: SignupType;
}

const Signup: FC<ISignupProps> = ({ type }) => {
  const signupTypes: { [key: string]: FC } = {
    email: SignupStep1,
    password: SignupStep2,
    profile: SignupStep3,
    phone: SignupStep4,
    validation: SignupStep5,
  };
  if (!signupTypes.hasOwnProperty(type)) {
    throw new Error("The component for signup onboarding does not exist");
  }
  return React.createElement(signupTypes[type]);
};

export default Signup;
