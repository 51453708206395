import { WebAuth } from 'auth0-js';

import { IAuth, IExtraOptions } from './interfaces/auth';

const getLeeway = ({
  internalOptions,
}: Pick<IExtraOptions, 'internalOptions'>): number | undefined => {
  const { leeway } = internalOptions;
  if (!leeway || typeof leeway === 'number') {
    return leeway;
  }
  const convertedLeeway = parseInt(leeway);
  if (isNaN(convertedLeeway)) {
    return leeway;
  }
  return convertedLeeway;
};

export default (options: IAuth) => {
  options.internalOptions.leeway = getLeeway(options);
  const params = Object.assign(
    {
      overrides: {
        __tenant: options.auth0Tenant,
        __token_issuer: options.authorizationServer.issuer,
      },
      domain: options.auth0Domain,
      clientID: options.clientID,
      redirectUri: options.callbackURL,
      responseType: 'code',
    },
    options.internalOptions
  );
  return new WebAuth(params);
};
