import * as Yup from "yup";
import { Alert, TextField } from "@yaydoo-suite/sso-components";
import { Button } from "@material-ui/core";
/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
import { FC, MutableRefObject, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";

import useMobileFooter from "../hooks/use-mobile-footer";
import usePage from "../hooks/use-page";
import useWebAuth from "../hooks/use-web-auth";
import { Page } from "../enums/page";
import { Portal } from "../molecules";

export interface ILogInFormValues {
  email: string;
  password: string;
}

interface ILoginFormProps {
  mobileFooter: null | MutableRefObject<null>;
}

const validationSchema: Yup.SchemaOf<
  Partial<ILogInFormValues>
> = Yup.object().shape({
  email: Yup.string()
    .email("El campo debe ser un correo electrónico válido")
    .required("Campo requerido"),
  password: Yup.string().required("Campo requerido"),
});

export const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%",
  },
  textField: {
    marginBottom: theme.spacing(3),
  },
  buttonsSection: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      position: "initial",
      padding: 0,
      marginTop: theme.spacing(4),
      flexDirection: "row-reverse",
      justifyContent: "space-between",
      alignItems: "center",
      backgroundColor: "none",
      boxShadow: "none",
      zIndex: 0,
    },
  },
  button: {
    width: "100%",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(2.5),
    fontSize: "1.6rem",
    fontWeight: 600,
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.success.light,
    },
    [theme.breakpoints.up("sm")]: {
      width: "50%",
      marginBottom: 0,
    },
  },
  link: {
    border: "none",
    fontFamily: "Poppins, sans-serif",
    fontSize: "1.6rem",
    fontWeight: 600,
    background: "none",
    color: theme.palette.info.main,
    cursor: "pointer",
  },
  alert: {
    marginBottom: theme.spacing(3),
  },
}));

const LogInForm: FC<ILoginFormProps> = ({ mobileFooter }) => {
  const classes = useStyles();
  const handleOnSubmit = async (values: ILogInFormValues): Promise<void> => {
    const { email, password } = values;
    try {
      await signin({
        email: email,
        password: password,
        connection: "Username-Password-Authentication",
      });
    } catch (error) {
      setSigninError(t("Usuario y/o contraseña no válida. Intenta nuevamente"));
    }
  };
  const logInForm = useFormik<ILogInFormValues>({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: handleOnSubmit,
  });
  const { t } = useTranslation();
  const { goTo } = usePage();
  const { signin } = useWebAuth();
  const [signinError, setSigninError] = useState<null | string>(null);
  const { showFooter } = useMobileFooter(mobileFooter);

  return (
    <form className={classes.form} onSubmit={logInForm.handleSubmit}>
      {signinError && (
        <Alert severity="error" className={classes.alert} error={signinError} />
      )}
      <TextField
        name="email"
        label={t("Correo electrónico")}
        placeholder={t("p. ej. {{example}}", { example: "jose@dominio.com" })}
        id="register-email"
        fullWidth
        type="text"
        variant="filled"
        className={classes.textField}
        onChange={logInForm.handleChange}
        value={logInForm.values.email}
        onBlur={logInForm.handleBlur}
        error={!!logInForm.errors.email}
        touched={logInForm.touched.email}
        helperText={logInForm.errors.email}
      />
      <TextField
        name="password"
        label={t("Contraseña")}
        placeholder={t("Escribe tu contraseña")}
        id="register-password"
        fullWidth
        variant="filled"
        className={classes.textField}
        type="password"
        onChange={logInForm.handleChange}
        value={logInForm.values.password}
        onBlur={logInForm.handleBlur}
        error={!!logInForm.errors.password}
        touched={logInForm.touched.password}
        helperText={logInForm.errors.password}
      />
      <Portal showPortal={showFooter} elementId="footer-mobile">
        <section className={classes.buttonsSection}>
          <Button
            type="submit"
            onClick={() => showFooter && logInForm.handleSubmit()}
            variant="contained"
            disabled={!logInForm.isValid || !logInForm.dirty}
            className={classes.button}
          >
            {t("Inicia sesión")}
          </Button>
          <button onClick={() => goTo(Page.Recovery)} className={classes.link}>
            {t("Olvidé mi contraseña")}
          </button>
        </section>
      </Portal>
    </form>
  );
};

export default LogInForm;
