import { Button, Logo, Typography } from "@yaydoo-suite/sso-components";
import { Container, Grid, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import pet from "../assets/images/pet.png";

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "100vh",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    backgroundColor: "#2f2741",
  },
  secondaryContainer: {
    maxWidth: theme.breakpoints.values.lg,
    [theme.breakpoints.up("md")]: {
      flexDirection: "row-reverse",
      alignItems: "center",
      height: "calc(100vh - 45px - 24px - 24px)",
      margin: "0 auto",
    },
  },
  secondarySection: {
    padding: `0 ${theme.spacing(2)}px`,
    marginTop: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
    },
  },
  imageSection: {
    display: "flex",
    justifyContent: "center",
  },
  mainText: {
    fontSize: "3.2rem",
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: `${theme.spacing(5)}px`,
    color: theme.palette.info.main,
    "& span": {
      fontWeight: theme.typography.fontWeightRegular,
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "55rem",
      marginBottom: theme.spacing(2),
      fontSize: "5.2rem",
      lineHeight: `${theme.spacing(6)}px`,
    },
  },
  secondaryText: {
    marginTop: theme.spacing(2),
    fontSize: "2rem",
    color: theme.palette.common.white,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(2),
    "& button": {
      width: "83%",
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  buttonDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "block",
      width: "fit-content",
      marginTop: theme.spacing(3),
    },
  },
}));

const ErrorPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const imageSectionClasses = [classes.secondarySection, classes.imageSection];

  return (
    <Container maxWidth="xl" className={classes.container}>
      <Grid container>
        <Grid item xs={12}>
          <Logo white />
        </Grid>
        <Grid item container className={classes.secondaryContainer}>
          <Grid item md={8} className={classes.secondarySection}>
            <Typography component="h1" className={classes.mainText}>
              {t("Vaya, hubo un error interno ")}
              <Typography component="span">{t("del servidor")}</Typography>
            </Typography>
            <Typography component="p" className={classes.secondaryText}>
              {t(
                "Ocurrió un error 500, por favor intenta en unos minutos nuevamente..."
              )}
            </Typography>
            <Button className={classes.buttonDesktop}>
              {t("Ir a la página principal")}
            </Button>
          </Grid>
          <Grid item xs={12} md={4} className={imageSectionClasses.join(" ")}>
            <img src={pet} alt={t("Mascota de Yayddo")} />
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.buttonContainer}>
          <Button>{t("Ir a la página principal")}</Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ErrorPage;
