/* eslint-disable no-use-before-define */
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import React, { FC, useState } from 'react';
import { InputAdornment, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import countries from '../resources/countries.json';

/**
 * NOTE: ISO 3166-1 alpha-2, ⚠️ No support for IE 11
 */
function countryToFlag(isoCode: string) {
  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: 140,
  },
  option: {
    fontSize: 14,
    '& > span': {
      marginRight: 10,
    },
  },
  inputRoot: {
    fontWeight: 600,
  },
  hasPopupIcon: {
    height: '100%',
    '& > div': {
      height: '100%',
    },
    '& > div > div': {
      height: '100%',
      borderRadius: '4px 0 0 4px',
    },
    '&&&&& div': {
      paddingRight: 0,
    },
  },
  clearIndicator: {
    display: 'none',
  },
  flagContainer: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    '& span': {
      transform: 'scale(4.5) translateY(1.5px);',
      fontFamily: 'Poppins, sans-serif',
    },
  },
  plusSymbol: {
    position: 'relative',
    left: '10px',
  },
}));

const defaultValue = {
  label: 'Mexico',
  phone: '52',
  code: 'MX',
  flag: 'https://www.countryflags.io/MX/flat/64.png',
};

interface ICountryType {
  code: string;
  label: string;
  phone: string;
  flag: string;
}

const CountryDialCode: FC = () => {
  const [country, setCountry] = useState<ICountryType | null>(defaultValue);
  const classes = useStyles();
  const { t } = useTranslation();

  const handleChange = (
    _event: React.ChangeEvent<{}>,
    newValue: ICountryType | null
  ) => {
    setCountry(newValue);
  };

  const filterOptions = createFilterOptions({
    stringify: (option: ICountryType) => option.label + option.phone,
  });

  return (
    <Autocomplete
      id="country-select"
      onChange={handleChange}
      filterOptions={filterOptions}
      options={(countries as unknown) as ICountryType[]}
      defaultValue={defaultValue}
      classes={
        {
          root: classes.root,
          option: classes.option,
          inputRoot: classes.inputRoot,
          hasPopupIcon: classes.hasPopupIcon,
          clearIndicator: classes.clearIndicator,
        } as any
      }
      autoHighlight
      noOptionsText={t('Sin resultados')}
      getOptionLabel={(option) => option.phone}
      renderOption={(option) => (
        <>
          <span>{countryToFlag(option.code)}</span>({option.code}) +
          {option.phone}
        </>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          InputProps={
            country
              ? {
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <div className={classes.flagContainer}>
                        <span>{countryToFlag(country.code)}</span>
                      </div>
                      <span className={classes.plusSymbol}>+</span>
                    </InputAdornment>
                  ),
                }
              : { ...params.InputProps }
          }
          inputProps={{
            ...params.inputProps,
            autoComplete: 'off',
          }}
        />
      )}
    />
  );
};

export default CountryDialCode;
