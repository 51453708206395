import { IOptionsRequest } from "../interfaces/request";
import { Request } from "../request";

export class ClientService {
  private http: Request;

  constructor() {
    this.http = new Request();
  }

  public async getInformation(params: IOptionsRequest) {
    return this.http.get("management/:id", params);
  }
}
