import { AuthorizeOptions, LogoutOptions } from 'auth0-js';
import { useContext } from 'react';

import { AuthContext } from '../contexts/auth.context';

interface IUseAuth {
  authorize: (options: AuthorizeOptions) => Promise<void>;
  logout: (options: LogoutOptions) => Promise<void>;
}

const useAuth = (): IUseAuth => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within a WebAuthProvider');
  }

  const { webAuth: webAuthContext } = context;

  const authorize = async (options: AuthorizeOptions) => {
    webAuthContext?.authorize(options);
  };
  const logout = async (options: LogoutOptions) => {
    webAuthContext?.logout(options);
  };

  return {
    authorize,
    logout,
  };
};

export default useAuth;
