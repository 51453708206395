import React, { FC } from 'react';
import { TextField, TextFieldProps } from '@material-ui/core';

const TextFieldMui: FC<
  Omit<TextFieldProps, 'variant'> & { variant: 'outlined' | 'filled' }
> = ({ ...validProps }) => {
  return <TextField {...validProps} />;
};

export default TextFieldMui;
