import * as Yup from "yup";
import i18n from "i18next";
import { TextField, useWizard, Wizard } from "@yaydoo-suite/sso-components";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";

import { SectionHeader } from "../../organisms";
import { useWizardForm } from "../../hooks";

interface IRecoverPasswordFormValues {
  email: string;
}

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email(i18n.t("El campo debe ser un correo electrónico válido"))
    .required(i18n.t("Campo requerido")),
});

const RecoverPasswordStep1 = () => {
  const { nextPage } = useWizard();
  const { t } = useTranslation();
  const handleOnSubmit = (values: IRecoverPasswordFormValues): void => {
    console.log(values);
    nextPage();
  };
  const signupForm = useFormik<IRecoverPasswordFormValues>({
    initialValues: {
      email: "",
    },
    validationSchema,
    onSubmit: handleOnSubmit,
  });
  useWizardForm<IRecoverPasswordFormValues>(signupForm);

  return (
    <>
      <Wizard.Header>
        <SectionHeader
          mainText={t("Olvidé mi contraseña")}
          secondaryText={t(
            "Introduce tu correo electrónico y en breve te enviaremos un link a tu bandeja para restaurar tu contraseña."
          )}
        />
      </Wizard.Header>
      <form onSubmit={signupForm.handleSubmit}>
        <TextField
          type="email"
          variant="filled"
          onChange={signupForm.handleChange}
          value={signupForm.values.email}
          name="email"
          fullWidth
          label={t("Correo electrónico")}
          placeholder={`${t("p. ej.")} john@email.com`}
          onBlur={signupForm.handleBlur}
          error={!!signupForm.errors.email}
          touched={signupForm.touched.email}
          helperText={signupForm.errors.email}
        />
      </form>
    </>
  );
};

export default RecoverPasswordStep1;
