import { useContext } from "react";

import { Page } from "../enums/page";
import { PageContext } from "../contexts/page.context";

interface IUsePage {
  getPage: () => Page;
  goTo: (newPage: Page) => void;
  goToPreviousPage: () => void;
}

const usePage = (): IUsePage => {
  const { page, setPage, lastPage, setLastPage } = useContext(PageContext);

  const goTo = (newPage: Page) => {
    setLastPage(page);
    setPage(newPage);
  };
  const getPage = () => page;
  const goToPreviousPage = () => {
    setLastPage(page);
    setPage(lastPage);
  };

  return {
    getPage,
    goTo,
    goToPreviousPage,
  };
};

export default usePage;
