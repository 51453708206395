import { Button, Grid, Typography } from "@material-ui/core";
import { Divider, GoogleIcon } from "@yaydoo-suite/sso-components";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

import useWebAuth from "../hooks/use-web-auth";

const useStyles = makeStyles((theme) => ({
  formAndSocialContainers: {
    padding: 0,
  },
  dividerContainer: {
    marginBottom: theme.spacing(3),
  },
  socialButton: {
    marginBottom: theme.spacing(3),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    fontSize: "1.6rem",
    textTransform: "none",
    alignItems: "stretch",
    "& > span > span": {
      marginTop: "2px",
    },
  },
}));

const LoginSocial = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { signinGoggle } = useWebAuth();

  return (
    <Grid
      item
      container
      component="section"
      xs={12}
      className={classes.formAndSocialContainers}
    >
      <Grid item container xs={12}>
        <Button
          variant="outlined"
          startIcon={<GoogleIcon />}
          fullWidth
          className={classes.socialButton}
          onClick={signinGoggle}
        >
          {t("Continua con Google")}
        </Button>
      </Grid>
      <Grid item className={classes.dividerContainer} xs={12}>
        <Divider>
          <Typography component="p">O</Typography>
        </Divider>
      </Grid>
    </Grid>
  );
};

export default LoginSocial;
