import React, { FC } from 'react';
import { Alert as AlertMui, AlertProps } from '@material-ui/lab';

export interface IAlertProps extends AlertProps {
  error: string;
}

const Alert: FC<IAlertProps> = ({ error, ...otherProps }) => {
  return (
    <AlertMui icon={false} {...otherProps}>
      {error}
    </AlertMui>
  );
};

export default Alert;
