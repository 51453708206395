import { get } from "lodash";

import { IResponseException } from "./interfaces/response-exception";
import { Response } from "./response";
import { SDKException } from "./sdk-exception";

export class ResponseExceptionFactory {
  public create(response: Response): IResponseException {
    const body = response.getDecodedBody().error;
    const message = get(body, "message", "Unknown SDK error");
    const code = get(body, "code", -1);
    const type = get(body, "type", "UnknownError");
    const subcode = get(body, "subcode");
    const params = get(body, "params");
    return new SDKException(message, {
      code,
      type,
      subcode,
      params,
    });
  }
}
