import NumberFormat, { NumberFormatProps } from 'react-number-format';
import React, { FC } from 'react';

import TextField from '../molecules/text-field';

export interface IPhoneTextField
  extends JSX.IntrinsicAttributes,
    JSX.IntrinsicClassAttributes<NumberFormat>,
    Readonly<NumberFormatProps>,
    Readonly<{ children?: React.ReactNode }> {}

const PhoneTextField: FC<IPhoneTextField> = (props) => {
  return (
    <NumberFormat
      format="(##) #### ####"
      placeholder="p. ej. 55 2908 4567"
      customInput={TextField}
      fullWidth
      {...props}
    />
  );
};

export default PhoneTextField;
