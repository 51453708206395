import { createContext, FC, useMemo, useState } from "react";

import { Page } from "../enums/page";

interface IPageContext {
  page: Page;
  setPage: (value: Page | ((prevState: Page) => Page)) => void;
  lastPage: Page;
  setLastPage: (value: Page | ((prevState: Page) => Page)) => void;
}
const PageContext = createContext<IPageContext>({} as any);

const PageProvider: FC = ({ children }) => {
  const [page, setPage] = useState<Page>(Page.Login);
  const [lastPage, setLastPage] = useState<Page>(Page.Login);

  const value = useMemo(
    () => ({
      page,
      setPage,
      lastPage,
      setLastPage,
    }),
    [page, setPage]
  );
  return <PageContext.Provider value={value}>{children}</PageContext.Provider>;
};

export { PageContext };
export default PageProvider;
