import React, { FC, useEffect, useState } from 'react';

import useWizard from './../hooks/use-wizard';

interface IWizardBodyProps {
  children: React.ReactNodeArray;
}

type ReactChildType = React.ReactChild | React.ReactFragment;

const WizardBody: FC<IWizardBodyProps> = ({ children }) => {
  const { isActive, setRoutes } = useWizard();
  const [contentChildren, setContentChildren] = useState<ReactChildType[]>([]);
  useEffect(() => {
    setContentChildren(React.Children.toArray(children));
  }, []);
  useEffect(() => {
    const routes = contentChildren.map((item: any) => item.props.path);
    setRoutes(routes);
  }, [contentChildren, setRoutes]);

  return <>{contentChildren.find((_child, index) => isActive(index))}</>;
};

export default WizardBody;
