import { useCallback, useContext, useEffect } from "react";

import UserRepository from "./../repositories/user.repository";
import { IUserProfile } from "./../interfaces/user";
import { ObjectHelper } from "./../helpers/object.helper";
import { UserContext } from "./../contexts/user.context";

interface IUseUser {
  getProfile: () => Partial<IUserProfile>;
  removeProfile: () => Promise<void>;
  updateProfile: (input: Partial<Omit<IUserProfile, "id">>) => Promise<void>;
}

const useUser = (): IUseUser => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUser must be used within a UserProvider");
  }
  const { profile, setProfile } = context;
  const objectHelper = ObjectHelper.getInstance();
  const userRepository = UserRepository.getInstance();
  const getProfile = useCallback((): Partial<IUserProfile> => {
    return profile;
  }, [profile]);
  const resetProfile = useCallback(() => {
    objectHelper.removeUserId();
    setProfile({
      id: "",
      name: "",
      email: "",
      password: "",
      phone: "",
      status: "",
    });
  }, [setProfile]);
  const removeProfile = useCallback(async () => {
    resetProfile();
  }, []);
  const updateProfile = useCallback(
    async (input: Partial<Omit<IUserProfile, "id">>) => {
      await userRepository.update(objectHelper.getUserId(), input);
      setProfile({
        id: objectHelper.getUserId(),
        name: input.name,
        email: input.email,
        password: input.password,
        phone: input.phone,
        status: input.status,
      });
    },
    [setProfile]
  );
  const init = useCallback(async () => {
    try {
      const { name, email, password, phone } = await userRepository.findOne({
        id: objectHelper.getUserId(),
      });
      await updateProfile({
        name,
        email,
        password,
        phone,
      });
    } catch (error) {
      resetProfile();
    }
  }, []);

  useEffect(() => {
    init();
  }, []);

  return {
    getProfile,
    removeProfile,
    updateProfile,
  };
};

export default useUser;
